import { defineStore } from "pinia";
// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useNavStore = defineStore("mainNav", {
  id: "mainNav",
  state: () => ({
    navItems: [
      {
        name: "Home",
        hash: "#home",
        label: "Home",
        enabled: true,
        subItems: [
          {
            name: "Home",
            hash: "#section1",
            label: "Meal Delivery",
            enabled: true,
          },
          {
            name: "Home",
            hash: "#section2",
            label: "How We work",
            enabled: true,
          },
          {
            name: "Home",
            hash: "#section3",
            label: "Request Weekly Menu",
            enabled: true,
          },
          {
            name: "Home",
            hash: "#section4",
            label: "FAQ",
            enabled: true,
          },
          {
            name: "Home",
            hash: "#section5",
            label: "Catering",
            enabled: true,
          },
          {
            name: "Home",
            hash: "#section6",
            label: "Happy Foodies",
            enabled: true,
          },
          {
            name: "Home",
            hash: "#section7",
            label: "Our Story",
            enabled: true,
          },
          // {
          //   name: "Home",
          //   hash: "#section8",
          //   label: "Contact",
          //   enabled: true,
          // },
        ],
      },
    ],
  }),
  getters: {
    navCollection: (state) => state.navItems,
    subItemsCollection: (state) =>
      state.navItems[0].subItems.filter((item) => item.enabled), // ask if there's a better way to out sub objects.
  },
});
