import { defineStore } from "pinia";
// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useFaqStore = defineStore("faqContent", {
  state: () => ({
    faqContent: [
      {
        title: "How much does each portion feed?",
        content:
          "Our portions tend to be on the larger side but each portion is meant to feed one person at one meal.",
        enabled: true,
      },
      {
        title: "When are the meals delivered?",
        content:
          "We deliver our meals fresh each day. So if you order Monday and Wednesday's meals we will deliver Monday’s dinner between 3-6pm on Monday and Wednesday’s dinner same time on Wednesday.",
        enabled: true,
      },
      {
        title: "How are the meals dropped off?",
        content:
          "Our delivery driver will drop off at your door in a paper bag. If you are concerned about your meal being left out for too long, we can set you up with a cooler for a one-time fee of $15 or you can just leave out a cooler for us. All our packaging is recyclable/compostable so if it’s available to you, please dispose of it all properly.",
        enabled: true,
      },
      {
        title: "How do I pay?",
        content:
          "Once you receive an email from us that confirms your order and total, you can send payment through Venmo to @beatsnroots. Other options are available if needed.",
        enabled: true,
      },
      {
        title: "Do you charge a delivery fee?",
        content:
          "There is no delivery fee if your order totals a minimum of $60. If not, we charge $5/delivery.",
        enabled: true,
      },
      {
        title: "Do I have to cook my meals or are they ready to eat?",
        content:
          "Since we want you to enjoy the meals as close to getting them fresh from our kitchen as possible, there is some light cooking or preparing sometimes. Meals like tacos and salads will come deconstructed so the ingredients all stay their best. There are times we’ll have you cook the noodles for a pasta dish because fresher is better than reheated. But you will always receive a set of instructions, which is our suggested process for the optimum way to prepare the meal. Any work on your part is minimum and so worth it!",
        enabled: true,
      },
      {
        title: "How do I get more of those drinkable sauces?",
        content:
          "Unfortunately, we haven’t quite got to bottling our sauces...yet! But in the meantime, you always have the option of purchasing any of the sauces on the menu for the week at $6 for 6 ounces. You’re going to want these sauces on EVERYTHING!!",
        enabled: true,
      },
      {
        title: "How long are the meals good for?",
        content:
          "Since you get everything freshly made each morning, your meal will be good for 5 days from delivery. If you don't plan on eating it the night of delivery, just transfer it to a sealed container for storage in the fridge until you're ready.",
        enabled: true,
      },
      {
        title: "What is your ordering and cancellation policy?",
        content:
          "Orders should be in by Saturday at 3pm for delivery for the next  week. Should you need to cancel your order, please do it by this deadline for a full refund.  Any cancelations after this deadline will receive no refund…so don’t cancel, gift it!",
        enabled: true,
      },

      {
        title: "Do you offer Gluten Free or Soy Free options?",
        content:
          "Our menu items will always be labeled so those with sensitivities and allergies are aware. We will sometimes offer gluten free substitutions so if it is not listed on the menu please feel free to inquire. But we do try to make sure there is variety in our meals so that there are options for everyone.",
        enabled: true,
      },
      {
        title: "Is everything 100% vegan?",
        content: "Yes! Everything we use is plant based.",
        enabled: true,
      },
      {
        title: "Are your meals healthy?",
        content:
          "Our food is nutritious without being overly health conscious. We do sometimes use fats or fry ingredients but nothing in excess. One of our goals is to veganize long-time favorite meals and come up with new creations using plant-based ingredients that are better for our bodies, the animals and our planet.",
        enabled: true,
      },
    ],
  }),
  getters: {
    faqs: (state) => state.faqContent, // copy of store key.
    filteredFaqs: (state) => state.faqContent.filter((item) => item.enabled), // filtered copy of store key.
  },
});
