<template>
  <section class="hero-main" :id="anchor" data-nav-static>
    <div class="hero-main__overlay container-xl">
      <div class="row">
        <div class="hero-main__col col-md-4">
          <img
            class="img-fluid logo"
            src="@/assets/logo/PNG/bnr-whtlogo-v-blk-clr.png"
            alt="Beats N' Roots Logo"
          />
          <div class="hero-main__tagline">food you can feel</div>
          <p class="hero-main__description">
            A vegan meal delivery and catering company in the Phoenix and
            Scottsdale area bringing fresh delicious meals to your table or
            event.
          </p>
        </div>
      </div>
    </div>
    <div class="hero-main__bg">
      <figure>
        <picture>
          <source
            media="(max-width: 767px)"
            srcset="@/assets/images/home-page/main/mobile/mainPage1.png"
          />
          <img src="@/assets/images/home-page/main/desktop/mainPage1.png" />
        </picture>
      </figure>
    </div>
  </section>
</template>
<script>
let anchor = 0;
export default {
  name: "Main-Hero",
  data: () => {
    return {
      anchor: "home",
    };
  },
  mounted() {},
  created() {
    this.inputId = anchor;

    setTimeout(() => {
      // calculate the height of the hero
      // shared.isStickyNav();
    }, 0);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.hero-main {
  $max-height: 740px;
  position: relative;
  background-color: $yellow;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  &__bg {
    width: 100%;

    figure {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      object-fit: cover;
      max-height: 46.5vh;
      object-position: 0 top;

      @include media-breakpoint-up(md) {
        object-fit: contain;
        object-position: 40vw bottom;
        max-height: $max-height;
      }

      @include media-breakpoint-up(xl) {
        height: 100vh;
      }
    }
  }

  &__overlay {
    @include media-breakpoint-up(md) {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include media-breakpoint-down(md) {
      .row {
        justify-content: center;
      }
    }
  }

  &__tagline {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.25rem;
    font-family: Barlow Condensed;
    margin-top: -0.575rem;
    margin-bottom: 1.5rem;
  }

  &__description {
    font-family: $font-family-condense;
    color: $teal;
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2.5rem 1.5rem;

    .row & {
      max-width: 350px;
    }

    .logo {
      width: 100%;
      max-width: 250px;
      //margin-bottom: 5%;
    }

    .back-top {
      width: 30px;
      margin-top: 7rem;
    }

    p {
      font-size: 1.375rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 7.5rem 2.5rem 5.5rem;
      margin-left: 4%;

      p {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
