<template>
  <header class="nav-main" data-nav id="nav">
    <nav
      class="nav-main__container container-xl"
      data-nav-sticky="show-scroll-up"
    >
      <button class="menu-btn" @click="navToggle">
        <div class="menu-btn__icon"></div>
      </button>
      <div class="nav-main__logo">
        <router-link to="/">
          <img
            src="@/assets/logo/PNG/bnr-logo-h-clr.png"
            alt="Beats N' Roots Logo"
          />
        </router-link>
      </div>
    </nav>
  </header>
  <div class="offcanvas-menu__backdrop" @click="navToggle" v-if="show"></div>
  <Transition name="slide-fade">
    <div class="offcanvas-menu" v-if="show">
      <div class="offcanvas-menu__header">
        <div class="offcanvas-menu__logo">
          <router-link @click="navToggle" to="/">
            <img
              src="@/assets/logo/PNG/bnr-logo-h-clr.png"
              alt="Beats N' Roots Logo"
            />
          </router-link>
        </div>
        <button
          type="button"
          class="btn-close text-reset"
          @click="navToggle"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <nav class="offcanvas-menu__nav">
          <ul>
            <li v-for="(item, $index) in navItems" v-bind:key="$index">
              <router-link
                :to="{ name: item.name, hash: item.hash }"
                @click="navToggle"
              >
                {{ item.label }}
              </router-link>
            </li>
            <ul>
              <li
                v-for="(subItem, $index) in subItemsCollection"
                v-bind:key="$index"
              >
                <router-link
                  :to="{ name: subItem.name, hash: subItem.hash }"
                  @click="navToggle"
                >
                  {{ subItem.label }}
                </router-link>
              </li>
            </ul>
            <li>
              <a
                href="https://www.instagram.com/beatsnroots/"
                @click="navToggle"
                ><img
                  src="@/assets/instagram.png"
                  alt="https://www.flaticon.com/free-icons/instagram- Instagram icons created by Freepik - Flaticon"
                  title="instagram icons"
                />Instagram</a
              >
            </li>
            <!-- <li>
              <router-link @click="navToggle" to="/about">about</router-link>
            </li>
            <li><a href="#" @click="navToggle">Back To Top</a></li> -->
          </ul>
        </nav>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { useNavStore } from "@/store/nav-store";
  // import shared from "@/shared";

  export default {
    el: "navMenu",

    setup() {
      const mainNavStore = useNavStore();
      const body = document.querySelector("body");
      return { mainNavStore, body };
    },
    // How do you manage state.
    data() {
      return {
        show: false,
      };
    },

    computed: {
      navItems() {
        return this.mainNavStore.navCollection;
      },

      subItemsCollection() {
        return this.mainNavStore.subItemsCollection;
      },
    },

    methods: {
      isStickyNav() {
        // Get Static Sibling Element.
        const staticEl = document.querySelector("header");
        // const navOffset = staticEl * staticEl;
        const stickyEl = document.querySelector(".nav-main");
        const stickyOrientation = stickyEl.getAttribute("data-nav-sticky");
        let lastScrollTop = window.pageYOffset;
        const hideNav = () => {
          window.setTimeout(() => {
            stickyEl.style.top = "-" + stickyEl.offsetHeight + "px";
            stickyEl.style.opacity = "0";
            stickyEl.classList.remove("isSticky");
          }, 300);
        };
        const showNav = () => {
          window.setTimeout(() => {
            stickyEl.style.top = "0";
            stickyEl.style.opacity = "1";
          }, 300);
        };
        if (window.pageYOffset <= staticEl.offsetHeight * 4) {
          showNav();
        } else {
          showNav();
        }
        window.addEventListener("scroll", () => {
          let currenScrollPos =
            window.pageYOffset || document.documentElement.scrollTop;
          if (window.pageYOffset >= staticEl.offsetHeight * 4) {
            stickyEl.classList.add("isSticky");
            if (currenScrollPos == staticEl.offsetHeight * 4) {
              stickyEl.classList.remove("isSticky");
            }
            if (currenScrollPos >= lastScrollTop) {
              // downscroll
              if (stickyOrientation === "show-scroll-down") {
                showNav();
              } else {
                hideNav();
              }
            } else {
              // upscroll
              if (stickyOrientation === "show-scroll-up") {
                hideNav();
              } else {
                showNav();
              }
            }
          } else {
            showNav();
            stickyEl.classList.remove("isSticky");
          }
          // Mobile or Negative Scrolling
          lastScrollTop = currenScrollPos <= 0 ? 0 : currenScrollPos;
        });
      },
      openMenu() {
        // console.log('open')
        this.body.style.overflow = "hidden";
        this.body.style.height = "100%";
      },
      closeMenu() {
        // console.log('closed')
        this.body.style.overflow = "";
        this.body.style.height = "";
      },
      navToggle() {
        this.show = !this.show;
        // console.log(this.show)
        if (this.show) {
          this.openMenu();
        } else {
          this.closeMenu();
        }
      },
    },

    mounted() {
      this.isStickyNav();
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/vendors/bootstrap/custom";

  /*
  Enter and leave animations can use different
  durations and timing functions.
*/
  .slide-fade-enter-active {
    transition: all 250ms ease-in-out;
  }

  .slide-fade-leave-active {
    transition: all 250ms ease-in-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }

  // Nav
  nav {
    a {
      font-weight: 600;
      color: $black;
      transition: all 300ms ease-in-out;

      &:hover {
        color: $teal;
        padding-left: 0.5rem;
        border-left: 3px solid #408e87;
      }
    }
  }

  // Top Nav
  .nav-main {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    z-index: 999;
    transition: all 300ms ease-in-out;

    &.isSticky {
      position: fixed;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 0 12px 1px rgba(0, 0, 0, 00.5);
    }

    &__container {
      display: flex;
      align-items: center;
      // background-color: #fedb49;
      padding: 15px 15px;
    }

    &__logo {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      &__logo {
        display: block;
        width: 150px;

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .offcanvas-menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;

    width: 75%;
    height: 100%;
    max-width: 400px;
    padding: 2rem;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 999;

    &__backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba($black, 00.5);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__logo {
      width: 150px;

      img {
        display: block;
        width: 100%;
      }
    }

    .btn-close {
      transition: transform 500ms ease-in-out;

      &:hover {
        transform: rotate(180deg);
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 0;

      // font-size: 1.6rem;
      a {
        text-decoration: none;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        ul {
          padding-left: 1.25rem;
        }
      }

      li {
        margin-bottom: 1rem;
      }
    }
  }

  .menu-btn {
    $gp: &;
    $hamburger-icon-ht: 3px;
    $hamburger-icon-clr: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 20px;
    height: 20px;
    border: none;
    padding: 0;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: transparent;

    &__icon {
      width: 100%;
      height: $hamburger-icon-ht;
      background-color: $hamburger-icon-clr;
    }

    &::before,
    &::after {
      display: block;
      content: "";
      width: 100%;
      height: $hamburger-icon-ht;
      background-color: $hamburger-icon-clr;
      transition: transform 300ms ease-in-out;
    }

    &:hover {
      #{$gp}__icon {
        transform: translateX(-4px);
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
</style>
