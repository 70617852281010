<template>
  <div>
    <h6>Thank you for your submission!</h6>
    <button
      type="button"
      data-bs-dismiss="modal"
      aria-label="Close"
      class="btn-yellow"
    >
      Close
    </button>
  </div>
</template>

<script>
  export default {
    name: "SubmissionSuccess",
  };
</script>

<style lang="scss" scoped>
  h6 {
    margin-bottom: 1.5rem;
  }
</style>
