<template>
  <HeroMain />
  <MealDelivery />
  <HowWeWork />
  <!-- <JoinTheFamily /> -->
  <FreqAskQues />
  <CateringSec />
  <HappyFoodies />
  <div id="section7">
    <template v-if="state_isVisible">
      <OurStoryMobile />
    </template>
    <template v-else>
      <OurStoryDesktop />
    </template>
  </div>
  <RequestForm />
</template>

<script>
// @ is an alias to /src
import HeroMain from "@/components/heroMain.vue";
import MealDelivery from "@/components/mealDelivery.vue";
import HowWeWork from "@/components/howWeWork.vue";
//import JoinTheFamily from "@/components/joinTheFamily.vue";
import FreqAskQues from "@/components/freqAskQues.vue";
import CateringSec from "@/components/cateringSec.vue";
import HappyFoodies from "@/components/happyFoodies.vue";
import OurStoryDesktop from "@/components/ourStoryDesktop.vue";
import OurStoryMobile from "@/components/ourStoryMobile.vue";
import RequestForm from "@/components/requestForm.vue";

export default {
  name: "HomeView",

  components: {
    HeroMain,
    MealDelivery,
    HowWeWork,
    // JoinTheFamily,
    FreqAskQues,
    CateringSec,
    HappyFoodies,
    OurStoryDesktop,
    OurStoryMobile,
    RequestForm,
  },
  props: {
    // visible: {
    //   required: true,
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      state_isVisible: false,
      mobileSize: 992,
    };
  },
  computed: {},
  created() {
    window.addEventListener("resize", this.isMobileScreenOnLoad());
  },
  unmounted() {
    window.removeEventListener("resize", this.isMobileScreenOnLoad);
  },
  mounted() {
    this.isMobileScreenOnLoad();
  },
  methods: {
    isVisbile() {
      this.state_isVisible = true;
      // console.log(this.state_isVisible);
    },
    notVisible() {
      this.state_isVisible = false;
      // console.log(this.state_isVisible);
    },
    isMobileScreenOnLoad() {
      // this.isMobile = !this.isMobile;
      const windowSize = window.innerWidth;
      if (windowSize >= this.mobileSize) {
        //desktop
        // console.log("Desktop: " + !this.state_isVisible);
        this.notVisible();
      } else {
        // mobile
        this.isVisbile();
        // console.log("mobile: " + this.state_isVisible);
      }
      window.addEventListener("resize", (e) => {
        const targetWindow = e.target.window.innerWidth;
        // console.log(targetWindow);
        // console.log(window.innerWidth)
        if (targetWindow >= this.mobileSize) {
          //desktop
          this.notVisible();
          // console.log("Desktop: " + !this.state_isVisible);
        } else {
          // mobile
          this.isVisbile();
          // console.log("mobile: " + this.state_isVisible);
        }
      });
    },
  },
};
</script>
