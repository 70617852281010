<template>
  <section class="freqAskQues" id="section4">
    <div class="freqAskQues__overlay">
      <div class="row">
        <div class="col-md-5 freqAskQues__col-bg">
          <h2 class="text">Frequently<br />Asked<br />Questions</h2>
          <div class="freqAskQues__bg">
            <img src="@/assets/images/home-page/FAQ/tofu-salad-clipped.png" />
          </div>
        </div>
        <div class="col-md-7 freqAskQues__col-text">
          <div class="accordion accordion-flush" id="accordianFlush">
            <div
              class="accordion-item"
              v-for="(faqItem, $index) in faqItems"
              :key="$index"
            >
              <h2 class="accordion-header" :id="`flush-heading--` + $index">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#flush-collapse--` + $index"
                  aria-expanded="false"
                  :aria-controls="`flush-collapse--` + $index"
                >
                  <span class="label"> {{ faqItem.title }}</span>
                </button>
              </h2>
              <div
                :id="`flush-collapse--` + $index"
                class="accordion-collapse collapse"
                :aria-labelledby="`flush-heading--` + $index"
                data-bs-parent="#accordianFlush"
              >
                <div class="accordion-body">
                  {{ faqItem.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { useFaqStore } from "@/store/faq-store";

  export default {
    name: "faq-content",

    setup() {
      const faqContentStore = useFaqStore();
      return { faqContentStore };
    },

    mounted() {},

    data() {
      // returning the getter.
      return {
        faqItems: this.faqContentStore.filteredFaqs,
      };
    },
    computed: {},

    methods: {},
  };
</script>
<style lang="scss">
  @import "@/assets/scss/vendors/bootstrap/custom";

  .freqAskQues {
    background-color: #d6eaea;
    padding: 1.5rem 1.5rem 0 0;

    @media screen and (max-width: $screen-md-max) {
      padding-bottom: 1.5rem;
    }

    &__overlay {
      @media screen and (min-width: calc($screen-xxl + 41px)) {
        max-width: 1478px;
        margin: 0 auto;
      }
    }

    &__col-bg {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        margin-left: -3.75rem;
        max-width: 47.5rem;
      }

      .text {
        padding-left: 4.5rem;

        @media screen and (max-width: $screen-md-max) {
          padding-left: 2.5rem;
        }
      }

      @include media-breakpoint-down(md) {
        img {
          display: none;
          width: 100%;
        }
      }
    }

    &__col-text {
      background-color: #d6eaea;
      padding: 1.5rem 0 0 0;

      @media screen and (max-width: $screen-md-max) {
        padding-left: 1.5rem;
        padding-top: 0;
      }

      .accordion {
        height: 100%;
      }
    }

    .accordion-button {
      font-weight: 600;
      font-size: 1.125rem;

      .label {
        margin-right: 1.5rem;
      }
    }
  }
</style>
