<template>
  <NavMenu></NavMenu>
  <router-view />
  <!-- <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view> -->
  <FooterSec></FooterSec>
</template>

<script>
  import FooterSec from "@/shared/footerSec.vue";
  import NavMenu from "@/shared/navMenu.vue";

  export default {
    name: "App",
    components: {
      FooterSec,
      NavMenu,
    },
    // setup() {

    // },
    // props: {
    //   visible: {
    //     required: true,
    //     type: Boolean,
    //     default: false
    //   }
    // },
    // data() {
    //   return {
    //     users: [],
    //   }
    // },
    // },
    // computed: {
    // },
    // created() {
    //   window.addEventListener("resize", this.isMobileScreenOnLoad());
    // },
    // unmounted() {
    //   window.removeEventListener("resize", this.isMobileScreenOnLoad);
    // },
    // async mounted() {
    //   const { data } = await this.axios.get(
    //     "https://jsonplaceholder.typicode.com/users"
    //   );
    //   this.users = data;
    //   console.log(data)
    // },
    // methods: {
    //   isVisbile() {
    //     this.state_isVisible = true
    //     console.log(this.state_isVisible)
    //   },
    //   notVisible() {
    //     this.state_isVisible = false
    //     console.log(this.state_isVisible)
    //   },
    //   isMobileScreenOnLoad() {
    //     // this.isMobile = !this.isMobile;
    //     const windowSize = window.innerWidth;
    //     if (windowSize >= this.mobileSize) {
    //       //desktop
    //       console.log('Desktop: ' + !this.state_isVisible)
    //       this.notVisible()
    //     } else {
    //       // mobile
    //       this.isVisbile()
    //       console.log('mobile: ' + this.state_isVisible)
    //     }
    //     window.addEventListener('resize', (e) => {
    //       const targetWindow = e.target.window.innerWidth
    //       console.log(targetWindow)
    //       // console.log(window.innerWidth)
    //       if (targetWindow >= this.mobileSize) {
    //         //desktop
    //         this.notVisible()
    //         console.log('Desktop: ' + !this.state_isVisible)
    //       } else {
    //         // mobile
    //         this.isVisbile()
    //         console.log('mobile: ' + this.state_isVisible)
    //       }
    //     });
    //   },
    // },
  };
</script>

<style lang="scss" scoped>
  /*
  Enter and leave animations can use different
  durations and timing functions.
*/
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 3500ms ease;
  }

  .fade-enter-from,
  .fade-leave-active {
    opacity: 0;
  }
</style>
