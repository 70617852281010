<template>
  <section class="happyFoodies" id="section6">
    <div class="container">
      <div class="row">
        <div class="col-md-5 foodie-pic-col">
          <div class="foodie-pic">
            <img
              class="img-fluid foodie-img"
              src="@/assets/images/home-page/happyfoodies/happy_foodie.jpg"
            />
          </div>
        </div>
        <div class="col-md-7 foodie-text">
          <div class="title">
            <h2>Happy Foodies</h2>
          </div>
          <div>
            <p>
              “Kalei and Jessica are amazing at their craft! We hired Beats n’
              Roots to cater a local retreat with vegan meals. They were
              punctual with clear communication. Now let’s talk food! WOW! Is
              all I can say. All of the ladies that attended the retreat raved
              about the food as did I. The flavors and the variety were out of
              this world. I’m looking forward to working with them in the near
              future. Simply Delicious!” -Kim B.
            </p>

            <p>
              “Beats n’ Roots has grown to be our “go to” source of food on our
              farm, not only for my family, but also for our catering events.
              Their creations are not only healthy and delicious but are
              delivered right to my door ensuring I eat well almost every night
              of the week. Kalei and Jessica have become part of our farm family
              and help us create eating experiences for our guests that “WOW”
              them with savory goodness while educating them on eating more
              mindfully and consciously. The worst days are the days they aren’t
              cooking or we don’t have something here for them to cater for.
              Sign up and be ready to salivate mindfully!” -Terra S., Hunkapi
              Farms
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script></script>
<style lang="scss">
  @import "@/assets/scss/vendors/bootstrap/custom";

  .happyFoodies {
    background-color: #ca3e2f;
    padding: 1.5rem 0;
    color: white;

    @media screen and (max-width: $screen-md-max) {
      padding-top: 0;
    }

    .foodie-pic-col {
      display: flex;
      align-items: center;
      .foodie-pic {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }

      .foodie-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 18rem;
        margin-top: 0;
        margin-bottom: 1.5rem;

        @media screen and (min-width: $screen-sm) {
          margin-top: 1.5rem;
        }
        @media screen and (min-width: $screen-md) {
          margin-top: 0rem;
          margin-bottom: 0rem;
          max-height: 100%;
        }

        @media screen and (min-width: $screen-lg) {
          max-height: 75%;
        }
      }
    }

    .foodie-text {
      align-self: center;
      padding-left: 1.5rem;

      @media screen and (max-width: $screen-md-max) {
        padding: 0;
      }

      @media screen and (max-width: $screen-sm-max) {
        padding: 0 1.5rem;
      }
    }
  }
</style>
