<template>
  <div
    class="modal fade"
    id="requestFormCatering"
    tabindex="-1"
    aria-labelledby="requestFormCateringLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="modal-gallery">
            <ThreeImgGallery
              :imgOne="{
                url: cateringGallery.imgOne.src,
                name: cateringGallery.imgOne.name,
              }"
              :imgTwo="{
                url: cateringGallery.imgTwo.src,
                name: cateringGallery.imgTwo.name,
              }"
              :imgThree="{
                url: cateringGallery.imgThree.src,
                name: cateringGallery.imgThree.name,
              }"
            >
            </ThreeImgGallery>
          </div>
          <div class="modal-title-container">
            <h5 class="modal-title" id="requestFormCateringLabel">
              Request Catering
            </h5>
          </div>
          <form
            v-if="!submitSuccess"
            name="catering-request-form"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="botfield"
            @submit.prevent="submitForm"
          >
            <input
              type="hidden"
              name="form-name"
              value="catering-request-form"
            />
            <div class="form-floating mb-3">
              <input
                v-model="cateringForm.name"
                class="form-control"
                id="floatingInput"
                placeholder="Name"
                required
              />
              <label for="floatingInput">Name</label>
            </div>
            <div class="form-floating mb-3">
              <input
                v-model="cateringForm.email"
                class="form-control"
                id="floatingInput"
                placeholder="Email"
                required
              />
              <label for="floatingInput">Email</label>
            </div>
            <div class="form-floating">
              <input
                v-model="cateringForm.phone"
                class="form-control"
                id="floatingPassword"
                placeholder="Phone"
                required
              />
              <label for="floatingPassword">Phone</label>
            </div>
            <div class="form-floating">
              <textarea
                class="form-control"
                v-model="cateringForm.message"
                placeholder="Write your message here"
                id="floatingTextarea2"
                style="height: 100px"
              ></textarea>
              <label for="floatingTextarea2">Message</label>
            </div>
            <button type="submit" class="btn-yellow">Submit</button>
          </form>
          <div v-else-if="submitFailed">
            <SubmissionFailed></SubmissionFailed>
          </div>
          <div v-else>
            <SubmissionSuccess></SubmissionSuccess>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ThreeImgGallery from "@/shared/threeImgGallery.vue";
  import SubmissionFailed from "./submissionFailed.vue";
  import SubmissionSuccess from "./submissionSuccess.vue";
  import axios from "axios";

  export default {
    name: "RequestFormCatering",

    data() {
      return {
        submitSuccess: false,
        submitFailed: false,
        cateringForm: {
          name: "",
          email: "",
          phone: "",
          message: "",
        },
        cateringGallery: {
          imgOne: {
            name: "plated catered dish",
            src: "home-page/catering/catering_img_1.jpg",
          },
          imgTwo: {
            name: "catered dessert cups",
            src: "home-page/catering/catering_img_2.jpg",
          },
          imgThree: {
            name: "vegan avocado toast",
            src: "home-page/catering/catering_img_3.jpg",
          },
        },
      };
    },
    methods: {
      encode(data) {
        return Object.keys(data)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&");
      },
      submitForm() {
        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" },
        };
        axios
          .post(
            "/",
            this.encode({
              "form-name": "catering-request-form",
              ...this.cateringForm,
            }),
            axiosConfig
          )
          .then(() => {
            this.submitSuccess = true;
            this.cateringForm.name = "";
            this.cateringForm.email = "";
            this.cateringForm.phone = "";
            this.cateringForm.message = "";
            // this.loadingText = false;
            //i redirect my app to '/' route once payload completed.
            // this.$router.push({ path: "/" });
          })
          .catch((error) => {
            if (error.response) {
              // eslint-disable-next-line no-alert
              this.submitFailed = true;
              alert(error.response.data); // => the response payload
            }
          });
      },
    },
    components: { ThreeImgGallery, SubmissionFailed, SubmissionSuccess },
  };
</script>
<style lang="scss">
  @import "@/assets/scss/vendors/bootstrap/custom";

  .modal-content {
    padding: 0 1.5rem;

    .modal-header {
      // position: relative;
      border: none;
      padding: 0;

      .btn-close {
        position: absolute;
        top: 32px;
        right: 20px;
        z-index: 1;
        background-color: white;
        padding: 0.75rem;
        opacity: 1;
        border-radius: 50%;
      }
    }

    .modal-body {
      padding: 2.5rem 0;
    }

    .threeImgGallery {
      grid-template-rows: repeat(2, 18vh);

      @media screen and (max-width: $screen-lg-max) {
        grid-template-rows: repeat(2, 12vh);
      }
    }

    .modal-title {
      margin: 1rem 0;
    }

    .form-floating {
      margin-bottom: 1rem;

      .form-control {
        background-color: #f2f4f8;
        border: none;
      }
    }
  }
</style>
