<template>
  <section class="ourstory-mobile" id="section7">
    <div class="container">
      <div class="ourstory-mobile__gallery-mobile gallery-mobile--one">
        <ThreeImgGallery
          :imgOne="{
            url: ourStoryGallery.imgOne.src,
            name: ourStoryGallery.imgOne.name,
          }"
          :imgTwo="{
            url: ourStoryGallery.imgTwo.src,
            name: ourStoryGallery.imgTwo.name,
            insta: ourStoryGallery.imgTwo.insta,
          }"
          :imgThree="{
            url: ourStoryGallery.imgThree.src,
            name: ourStoryGallery.imgThree.name,
          }"
        >
        </ThreeImgGallery>
      </div>
      <div class="ourstory-mobile__text">
        <h2>Our Story</h2>
        <p>
          Beats n’ Roots began in a kitchen where family and friends cooked,
          danced and ate. It was an idea to get fresh plant based meals out to
          people who wanted to be more mindful about the food they were eating
          or simply get more veggies in their life. It was a chance for chef
          Kalei Kauahi to take his passion and talent and deliver it to your
          doorstep. And that’s what we did! It has become a vegan meal delivery
          and catering company bringing fresh delicious meals to your table or
          event. Kalei, along with his wife Jessica and their children, teamed
          up to prepare, package and deliver dinner all around town. This family
          affair is quickly growing and we’re excited to get our food out to
          you!
        </p>
        <div class="ourstory-mobile__btn-container">
          <router-link to="/about" class="btn-yellow">Read More…</router-link>
        </div>
      </div>
      <div class="ourstory-mobile__gallery-mobile gallery-mobile--two">
        <ThreeImgGallery
          :imgOne="{
            url: ourStoryGalleryTwo.imgOne.src,
            name: ourStoryGalleryTwo.imgOne.name,
          }"
          :imgTwo="{
            url: ourStoryGalleryTwo.imgTwo.src,
            name: ourStoryGalleryTwo.imgTwo.name,
          }"
          :imgThree="{
            url: ourStoryGalleryTwo.imgThree.src,
            name: ourStoryGalleryTwo.imgThree.name,
          }"
        >
        </ThreeImgGallery>
      </div>
    </div>
  </section>
</template>

<script>
import ThreeImgGallery from "@/shared/threeImgGallery.vue";

export default {
  data() {
    return {
      ourStoryGallery: {
        imgOne: {
          name: "chef kalei kauahi",
          src: "home-page/ourstory/ourstory_item_3.jpg",
        },
        imgTwo: {
          name: "crispy tofu and rice",
          src: "home-page/ourstory/ourstory_item_2.jpg",
          insta: true,
        },
        imgThree: {
          name: "marinated mushrooms",
          src: "home-page/ourstory/ourstory_item_4.jpg",
        },
      },
      ourStoryGalleryTwo: {
        imgOne: {
          name: "jessica kauahi",
          src: "home-page/ourstory/ourstory_item_5.jpg",
        },
        imgTwo: {
          name: "fried squash blossom with pico de gallo",
          src: "home-page/ourstory/ourstory_item_7.jpg",
        },
        imgThree: {
          name: "ratatouille with pasta",
          src: "home-page/ourstory/ourstory_item_6.jpg",
        },
      },
    };
  },
  components: { ThreeImgGallery },
};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.ourstory-mobile {
  margin: 1.5rem 0;

  @media screen and (max-width: $screen-sm-max) {
    margin: 0 0;

    .container {
      padding: 0 0;
    }
  }

  &__text {
    background-color: #336f80;
    color: white;
    margin: 0.325rem 0;

    @media screen and (max-width: $screen-sm-max) {
      margin: 0;
    }

    @media screen and (max-width: $screen-lg-max) {
      padding: 1.5rem;
    }
  }

  &__btn-container {
    padding: 1.5rem 0;
  }
}

.gallery-mobile {
  &--one {
    .threeImgGallery {
      $gp: &;
      grid-template-rows: repeat(2, 14vh);

      @media screen and (min-width: $screen-sm) {
        grid-template-rows: repeat(2, 25vh);
      }

      &__img {
        object-position: center;
      }

      &__item-3 {
        .threeImgGallery__img {
          object-position: center bottom;
        }
      }
    }
  }

  &--two {
    .threeImgGallery {
      grid-template-rows: repeat(2, 14vh);

      @media screen and (min-width: $screen-sm) {
        grid-template-rows: repeat(2, 25vh);
      }

      &__img {
        object-position: center;
      }
    }
  }
}
</style>
