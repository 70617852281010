<template>
  <section class="cateringSec" id="section5">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 catering-text">
          <div>
            <h2>Catering</h2>
          </div>
          <div>
            <p>
              Let us cater your next event! If you’re looking for some delicious
              plant-based appetizers, build your own stations, table service and
              more we’ve got what you need. We will help come up with just the
              right assortment of eats to impress your guests…even the
              carnivores! We are committed to freshness, quality, detail and
              bringing all the good vibes! Our goal is to surpass your
              expectations and provide you with amazing food that everyone will
              be talking about.
            </p>
            <p>
              Contact us for your event of up to 100 people and we can send you
              a sample menu or work with you to come up with something creative
              and new. We’re excited to help make your event one to remember!
            </p>
          </div>
          <div class="yellowButton">
            <button
              data-bs-toggle="modal"
              data-bs-target="#requestFormCatering"
              class="btn-yellow"
            >
              Request Catering
            </button>
          </div>
        </div>
        <div class="col-lg-7 catering-gallery">
          <ThreeImgGallery
            :imgOne="{
              url: cateringGallery.imgOne.src,
              name: cateringGallery.imgOne.name,
            }"
            :imgTwo="{
              url: cateringGallery.imgTwo.src,
              name: cateringGallery.imgTwo.name,
            }"
            :imgThree="{
              url: cateringGallery.imgThree.src,
              name: cateringGallery.imgThree.name,
            }"
          >
          </ThreeImgGallery>
        </div>
      </div>
    </div>
  </section>
  <RequestFormCatering></RequestFormCatering>
</template>

<script>
  import ThreeImgGallery from "@/shared/threeImgGallery.vue";
  import RequestFormCatering from "@/components/requestFormCatering.vue";

  export default {
    name: "CateringSection",
    data() {
      return {
        cateringGallery: {
          imgOne: {
            name: "plated catered dish",
            src: "home-page/catering/catering_img_1.jpg",
          },
          imgTwo: {
            name: "catered dessert cups",
            src: "home-page/catering/catering_img_2.jpg",
          },
          imgThree: {
            name: "vegan avocado toast",
            src: "home-page/catering/catering_img_3.jpg",
          },
        },
      };
    },
    components: { ThreeImgGallery, RequestFormCatering },
    // methods: {
    //   openModal() {
    //     this.$refs.modal.openModal();
    //   },
    // },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/vendors/bootstrap/custom";

  .cateringSec {
    margin: 1.5rem 0;

    @media screen and (max-width: $screen-sm-max) {
      margin-top: 0;
    }
  }

  .catering-text {
    padding: 0 1.5rem 0 0;

    @media screen and (max-width: $screen-lg-max) {
      order: 2;
    }

    @media screen and (max-width: $screen-sm-max) {
      padding: 0 1.5rem;
    }
  }

  .yellowButton {
    margin-top: 1.5rem;
  }

  .catering-gallery {
    @media screen and (max-width: $screen-lg-max) {
      order: 1;
      margin-bottom: 1.5rem;
    }

    .threeImgGallery {
      @media screen and (min-width: $screen-lg) and (max-width: $screen-xl-max) {
        grid-template-rows: repeat(2, 22vw);
      }
    }
    .threeImgGallery__item-3 {
      img {
        object-position: center bottom;
      }
    }
  }
</style>
