import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    name: "Home",
    path: "/",
    component: HomeView,
    meta: { transition: "slide-left" },
  },
  {
    path: "/about",
    name: "about",
    meta: { transition: "slide-right" },

    // component: AboutView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return new Promise((resolve) => {
          const windowWidth = window.innerWidth >= 768 ? 82 : 50;
          setTimeout(() => {
            resolve({
              el: to.hash,
              behavior: "smooth",
              // top: 0 //return the offset here if condition
              top: windowWidth, //Offset height of nav
            });
          }, 500);
        });
      } else {
        return { top: 0 };
      }
    }
  },
});

export default router;
