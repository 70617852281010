<template>
  <section class="footerSec" id="section8">
    <div class="container">
      <!-- <div class="row justify-content-md-center text-center">
        <div>
          <img src="@/assets/logo/SVG/bnr_logo-h-wht.svg" class="footer-logo" />
        </div>
        <div class="footerSec__tagline">food you can feel</div>
      </div> -->
      <div class="row justify-content-md-center mid-section">
        <div class="col-sm-4 footerSec__col-one">
          <ul>
            <li>
              <a href="tel:6029899092">
                <span class="icon icon--white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 24"
                    style="enable-background: new 0 0 15 24"
                    width="15px"
                  >
                    <path
                      d="M12.7,0H2.3C1,0,0,1,0,2.3v1v1.2v15.2v1.2v0.8C0,23,1,24,2.3,24h10.4c1.3,0,2.3-1,2.3-2.3v-0.8v-1.2V4.5V3.3v-1
	C15,1,14,0,12.7,0z M1.1,2.9c0-0.8,0.7-1.5,1.5-1.5h9.7c0.8,0,1.5,0.7,1.5,1.5v0.4H1.1V2.9z M13.9,21.1c0,0.8-0.7,1.5-1.5,1.5H2.7
	c-0.8,0-1.5-0.7-1.5-1.5v-0.3h12.7V21.1z M13.9,19.7H1.1V4.5h12.7V19.7z"
                    />
                  </svg>
                </span>
                <span class="icon-text">602-989-9092</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:info@beatsnroots.com?subject=Requesting%20More%20Information"
              >
                <span class="icon icon--white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="enable-background: new 0 0 24 24"
                    width="24px"
                  >
                    <path
                      d="M22.4,2.5H1.6C0.7,2.5,0,3.2,0,4.1v15.6c0,0.9,0.7,1.6,1.6,1.6h20.7c0.9,0,1.6-0.7,1.6-1.6V4.1C24,3.2,23.3,2.5,22.4,2.5z M2.2,3.7h19.7c0.3,0,0.6,0.2,0.7,0.4l-10.1,7.2c-0.4,0.3-1,0.3-1.4,0L1.4,4.2C1.5,3.9,1.8,3.7,2.2,3.7z M22.8,19.2c0,0.5-0.4,1-1,1
	H2.2c-0.5,0-1-0.4-1-1V5.5l9.9,7.3c0.4,0.3,1,0.3,1.4,0l10.2-7.4c0,0,0,0,0,0V19.2z"
                    />
                  </svg>
                </span>
                <span class="icon-text">info@beatsnroots.com</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/beatsnroots/"
                title="Beats n Roots Instagram"
                target="_blank"
              >
                <span class="icon icon--instagram">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                  >
                    <path
                      d="M17,0H7C3.2,0,0,3.2,0,7v10c0,3.9,3.2,7,7,7h10c3.9,0,7-3.1,7-7V7C24,3.2,20.9,0,17,0z M22.6,17.3c0,1.4-0.6,2.7-1.5,3.7
                                s-2.3,1.5-3.7,1.5H6.7C5.3,22.6,4,22,3,21c-1-1-1.6-2.3-1.6-3.7V6.7C1.4,5.3,2,4,3,3c1-1,2.3-1.6,3.7-1.6h10.6C18.7,1.4,20,2,21,3
                                s1.5,2.3,1.5,3.7V17.3z M19.8,5.5c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C19.2,4.2,19.8,4.8,19.8,5.5z M12,6
                                c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S15.3,6,12,6z M12,16.6c-2.6,0-4.6-2.1-4.6-4.6S9.4,7.4,12,7.4s4.6,2.1,4.6,4.6
                                S14.5,16.6,12,16.6z"
                    />
                  </svg>
                </span>
              </a>
              <a
                href="https://www.facebook.com/beatsnrootsmealdelivery"
                title="Beats n Roots Facebook"
                target="_blank"
              >
                <span class="icon icon--white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 128"
                    style="enable-background: new 0 0 128 128"
                    width="24px"
                  >
                    <path
                      d="M115.5,0.1H12.5C5.7,0.1,0.1,5.7,0.1,12.5v103.1c0,6.8,5.5,12.3,12.3,12.3h49.3c1.9,0,3.4-1.5,3.4-3.4
	s-1.5-3.4-3.4-3.4H12.5c-3.1,0-5.6-2.5-5.6-5.6V12.5c0-3.1,2.5-5.6,5.6-5.6h103.1c3.1,0,5.6,2.5,5.6,5.6v103.1
	c0,3.1-2.5,5.6-5.6,5.6H92V83h12.3c1.7,0,3.1-1.3,3.3-2.9l2.2-17.9c0.1-1-0.2-1.9-0.8-2.6c-0.6-0.7-1.6-1.1-2.5-1.1H92V43.8
	c0-0.6,0.5-1.1,1.1-1.1h13.4c1.9,0,3.4-1.5,3.4-3.4V21.4c0-1.9-1.5-3.4-3.4-3.4H88.6c-11.7,0-21.3,9.5-21.3,21.3v19H52.8
	c-1.9,0-3.4,1.5-3.4,3.4v17.9c0,1.9,1.5,3.4,3.4,3.4h14.6v41.5c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4V79.7c0-1.9-1.5-3.4-3.4-3.4
	H56.2V65.1h14.6c1.9,0,3.4-1.5,3.4-3.4V39.4c0-8,6.5-14.6,14.6-14.6h14.6V36H93.1c-4.3,0-7.8,3.5-7.8,7.8v17.9
	c0,1.9,1.5,3.4,3.4,3.4h14.1l-1.4,11.2H88.6c-1.9,0-3.4,1.5-3.4,3.4v44.8c0,1.9,1.5,3.4,3.4,3.4c0,0,0,0,0,0s0,0,0,0h26.9
	c6.8,0,12.3-5.5,12.3-12.3V12.5C127.9,5.7,122.3,0.1,115.5,0.1z"
                    />
                  </svg>
                </span>
              </a>
              <span class="icon-text">social</span>
            </li>
          </ul>
        </div>

        <div class="col-sm-4 footerSec__col-two text-center">
          <div>
            <img
              src="@/assets/logo/SVG/bnr_logo-h-wht.svg"
              class="footer-logo"
            />
          </div>
          <div class="footerSec__tagline">food you can feel</div>
          <!-- <div>Be Healthy</div>
          <div>Love Each Other</div>
          <div>Dream Big</div> -->
        </div>
        <div class="col-sm-4 footerSec__col-three text-center">
          <div><a href="#">Back to Top</a></div>
          <div v-for="(subItem, $index) in subItems" v-bind:key="$index">
            <router-link :to="{ name: subItem.name, hash: subItem.hash }">
              {{ subItem.label }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="boring-text">
        <span>©2022 Beats n’ Roots. All Rights Reserved.</span>
        <a
          data-bs-toggle="modal"
          data-bs-target="#privacyPolicyModal"
          class="privacy-policy"
          >Privacy Policy</a
        >
      </div>
    </div>
  </section>
  <PrivacyPolicy></PrivacyPolicy>
</template>

<script>
import { useNavStore } from "@/store/nav-store";
import PrivacyPolicy from "@/shared/privacyPolicy.vue";

export default {
  setup() {
    const mainNav = useNavStore();
    const subItems = mainNav.navItems[0].subItems;
    return { subItems };
  },
  components: {
    PrivacyPolicy,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom.scss";

.footerSec {
  background-color: #dd753d;
  padding: 1.5rem 0;
  color: white;

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  .icon-text {
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .icon--instagram {
    @media screen and (max-width: $screen-sm-max) {
      margin-right: 0;
      padding-right: 1rem;
    }
  }

  .footer-logo {
    max-width: 350px;
    width: 100%;
    // margin-bottom: 1.5rem;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 0;
    }
  }

  &__tagline {
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Barlow Condensed";
    font-size: 1.25rem;
    padding-left: 18%;
    margin-top: -1rem;

    @media screen and (max-width: $screen-sm-max) {
      padding-left: 0;
    }
  }

  .mid-section {
    margin-bottom: 2rem;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 2rem;
    }
  }

  .boring-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.75rem;

    @media screen and (max-width: $screen-sm-max) {
      flex-direction: column;
      text-align: center;
    }
    .privacy-policy {
      cursor: pointer;
    }
  }

  &__col-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      @media screen and (max-width: $screen-sm-max) {
        display: flex;
      }

      li {
        margin-bottom: 1rem;

        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 0;
          padding-right: 1rem;
        }

        &:last-child {
          margin-bottom: 0;

          @media screen and (max-width: $screen-sm-max) {
            padding-right: 0;
          }
        }
      }
    }

    @media screen and (max-width: $screen-sm-max) {
      order: 3;
    }
  }

  &__col-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.375rem;
    font-weight: 500;

    // &:before,
    // &:after {
    //   display: inline-block;
    //   content: "";
    //   width: 20%;
    //   border-top: 0.1rem solid white;

    //   @media screen and (max-width: $screen-md-max) {
    //     margin: 1rem 0;
    //   }
    // }

    @media screen and (max-width: $screen-sm-max) {
      order: 1;
      margin-bottom: 1.5rem;
    }
  }

  &__col-three {
    line-height: 1.5rem;
    @media screen and (max-width: $screen-sm-max) {
      order: 2;
      margin-bottom: 2rem;
    }
  }
}
</style>
