<template>
  <section class="ourStory-desktop ourStory">
    <div class="container">
      <div class="row">
        <div class="col-md-12 our-story-gallery">
          <figure class="gallery__item our-story-item-1">
            <div>
              <h2>Our Story</h2>
            </div>
            <div>
              <p>
                Beats n’ Roots began in a kitchen where family and friends
                cooked, danced and ate. It was an idea to get fresh plant based
                meals out to people who wanted to be more mindful about the food
                they were eating or simply get more veggies in their life. It
                was a chance for chef Kalei Kauahi to take his passion and
                talent and deliver it to your doorstep. And that’s what we did!
                It has become a vegan meal delivery and catering company
                bringing fresh delicious meals to your table or event. Kalei,
                along with his wife Jessica and their children, teamed up to
                prepare, package and deliver dinner all around town. This family
                affair is quickly growing and we’re excited to get our food out
                to you!
              </p>

              <div class="our-story-gallery__btn-container">
                <router-link class="btn-yellow" @click="navToggle" to="/about"
                  >Read More</router-link
                >
              </div>
            </div>
          </figure>
          <figure class="gallery__item our-story-item-2">
            <a
              href="https://www.instagram.com/beatsnroots/"
              title="Instagram Beats n Roots"
              class="our-story-item-2__insta"
            >
              <span class="icon icon--black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  height="20"
                  width="20"
                >
                  <path
                    d="M17,0H7C3.2,0,0,3.2,0,7v10c0,3.9,3.2,7,7,7h10c3.9,0,7-3.1,7-7V7C24,3.2,20.9,0,17,0z M22.6,17.3c0,1.4-0.6,2.7-1.5,3.7
                                s-2.3,1.5-3.7,1.5H6.7C5.3,22.6,4,22,3,21c-1-1-1.6-2.3-1.6-3.7V6.7C1.4,5.3,2,4,3,3c1-1,2.3-1.6,3.7-1.6h10.6C18.7,1.4,20,2,21,3
                                s1.5,2.3,1.5,3.7V17.3z M19.8,5.5c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C19.2,4.2,19.8,4.8,19.8,5.5z M12,6
                                c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S15.3,6,12,6z M12,16.6c-2.6,0-4.6-2.1-4.6-4.6S9.4,7.4,12,7.4s4.6,2.1,4.6,4.6
                                S14.5,16.6,12,16.6z"
                  />
                </svg>
              </span>
            </a>
            <img
              src="@/assets/images/home-page/ourstory/ourstory_item_2.jpg"
              class="our-story-item"
              alt="Image 2"
            />
          </figure>
          <figure class="gallery__item our-story-item-3">
            <img
              src="@/assets/images/home-page/ourstory/ourstory_item_3.jpg"
              class="our-story-item"
              alt="Image 3"
            />
          </figure>
          <figure class="gallery__item our-story-item-4">
            <img
              src="@/assets/images/home-page/ourstory/ourstory_item_4.jpg"
              class="our-story-item"
              alt="Image 4"
            />
          </figure>
          <figure class="gallery__item our-story-item-5">
            <img
              src="@/assets/images/home-page/ourstory/ourstory_item_5.jpg"
              class="our-story-item"
              alt="Image 5"
            />
          </figure>
          <figure class="gallery__item our-story-item-6">
            <img
              src="@/assets/images/home-page/ourstory/ourstory_item_6.jpg"
              class="our-story-item"
              alt="Image 6"
            />
          </figure>
          <figure class="gallery__item our-story-item-7">
            <img
              src="@/assets/images/home-page/ourstory/ourstory_item_7.jpg"
              class="our-story-item"
              alt="Image 7"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  el: "ourStory",

  data() {
    return {};
  },

  computed: {},
  methods: {},

  mounted() {},
};
</script>
<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.ourStory {
  padding: 1.5rem 0;
}

.our-story-gallery {
  display: grid;
  column-gap: 0.325rem;
  row-gap: 0.325rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 16.5rem);

  // @media screen and (min-width: 1200px) {
  //   // grid-template-rows: repeat(4, 30vh);
  //   grid-template-rows: repeat(4, 16.5rem);
  // }
  // /*ipad PRo 12.9"*/
  // /* Portrait */
  // /* Declare the same value for min- and max-width to avoid colliding with desktops */
  // /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  // @media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  //   grid-template-rows: repeat(4, 20vh);
  // }

  // @media screen and (min-width: 1440px) {
  //   grid-template-rows: repeat(4, 16.5rem);
  // }

  &__btn-container {
    margin-top: 1.5rem;
  }
}

.our-story-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.our-story-item-1 {
  background-color: #336f80;
  color: white;
  line-height: 2rem;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  @media screen and (min-width: $screen-lg) {
    padding: 2rem;
  }

  @media screen and (min-width: $screen-xl) {
    padding: 5%;
  }
}

.our-story-item-2 {
  position: relative;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;

  &__insta {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    background-color: rgba(white, 40%);
    z-index: 1;

    .icon {
      svg {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.our-story-item-3 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

.our-story-item-4 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.our-story-item-5 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;
}

.our-story-item-6 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}

.our-story-item-7 {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
}
</style>
