<template>
  <section class="mealDelivery" id="section1">
    <div class="container">
      <div class="row">
        <div class="col-md-4 meal-delivery-text">
          <div>
            <h2>Meal Delivery</h2>
          </div>
          <div>
            <p>
              We are a meal delivery service bringing you fresh made plant-based
              meals that offer variety to familiar dishes and exposure to new
              ingredients and flavors. Our meals are prepared from scratch each
              morning of delivery and brought to you in time for dinner that
              night with minimal work on your part.
            </p>
          </div>
        </div>
        <div class="col-md-8 swiper-container">
          <swiper
            ref="mySwiper"
            :slidesPerView="'auto'"
            :cssMode="true"
            :space-between="5"
            :autoplay="false"
            :loop="false"
            :navigation="true"
            :lazy="true"
            :scrollbar="{
              hide: false,
            }"
            :pagination="false"
            :modules="modules"
            class="mySwiper"
          >
            <swiper-slide
              v-for="(img, $index) in swiperImages"
              v-bind:key="$index"
              :class="'swiper-slide--' + img.orientaion"
            >
              <div class="slide-container">
                <img
                  :src="require('@/assets/images/' + img.url)"
                  :alt="img.alt"
                  class="swiper-lazy"
                />
                <!-- <div
                  class="swiper-lazy-preloader swiper-lazy-preloader-white"
                ></div> -->
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import {
    Navigation,
    Pagination,
    A11y,
    Lazy,
    Autoplay,
    Scrollbar,
  } from "swiper";
  import "swiper/css";
  import "swiper/css/bundle";

  export default {
    data() {
      return {
        swiperImages: [
          {
            url: "home-page/mealdelivery/slide_img_1.jpg",
            alt: "img-1",
            orientaion: "landscape",
          },
          {
            url: "home-page/mealdelivery/slide_img_2.jpg",
            alt: "img-2",
            orientaion: "landscape",
          },
          {
            url: "home-page/mealdelivery/slide_img_3.jpg",
            alt: "img-3",
            orientaion: "portrait",
          },
          {
            url: "home-page/mealdelivery/slide_img_4.jpg",
            alt: "img-4",
            orientaion: "portrait",
          },
          {
            url: "home-page/mealdelivery/slide_img_5.jpg",
            alt: "img-5",
            orientaion: "landscape",
          },
          {
            url: "home-page/mealdelivery/slide_img_6.jpg",
            alt: "img-6",
            orientaion: "landscape",
          },
          {
            url: "home-page/mealdelivery/slide_img_7.jpg",
            alt: "img-7",
            orientaion: "portrait",
          },
          {
            url: "home-page/mealdelivery/slide_img_8.jpg",
            alt: "img-8",
            orientaion: "portrait",
          },
          {
            url: "home-page/mealdelivery/slide_img_9.jpg",
            alt: "img-9",
            orientaion: "landscape",
          },
          {
            url: "home-page/mealdelivery/slide_img_10.jpg",
            alt: "img-10",
            orientaion: "portrait",
          },
        ],
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        preloadImages: true,
        lazy: {
          threshold: 50,
          sequential: false,
          observer: true,
          loadPrevNext: true,
          loadPrevNextAmount: 2,
          loadOnTransitionStart: false,
        },
        pagination: {
          clickable: true,
        },
        modules: [Navigation, Pagination, A11y, Lazy, Autoplay, Scrollbar],
      };
    },
    method: {
      updatedManually() {
        // this.$refs.mySwiper.swiperInstance.update();
      },
    },
  };
</script>
<style lang="scss">
  @import "@/assets/scss/vendors/bootstrap/custom";

  .mealDelivery {
    padding: 1.5rem 0;

    .title {
      h2 {
        margin: 20px 0;
      }
    }

    .meal-delivery-text {
      @media screen and (max-width: $screen-sm-max) {
        padding: 0 1.5rem;
      }
    }
  }

  .swiper-container {
    padding-left: 1.5rem;

    @media screen and (max-width: $screen-md-max) {
      padding-left: 0;
    }
  }

  .mySwiper .swiper-pagination {
    position: relative;
    bottom: 0px;

    .swiper-pagination-bullet-active {
      background: $primary;
    }
  }

  .swiper-slide {
    transition: all 300ms ease-in-out;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--landscape {
      width: 95%;

      @media screen and (min-width: $screen-lg) {
        width: 75%;
      }
    }

    &--portrait {
      width: 50%;

      @media screen and (min-width: $screen-lg) {
        width: 35%;
      }
    }

    .slide-container {
      height: 300px;
      color: black;
      @media screen and (min-width: $screen-md) {
        height: 350px;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    color: white;
    @media screen and (min-width: $screen-md) {
      // color: $teal;
      display: block;
    }
  }

  .swiper-scrollbar-drag {
    background: $teal-500;
  }
</style>
