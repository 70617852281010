<template>
  <section class="howWeWork" id="section2">
    <div class="container text-center">
      <div>
        <h2>How We Work</h2>
      </div>
      <div class="row">
        <div class="col-md-4">
          <h3>
            <img src="@/assets/icons/icon-forkknife.png" alt="weekly menu" />
            Weekly Menu
          </h3>
          <p>
            Our menu offers weeknight dinners, and options change weekly. But
            don't worry, your favorites will always make their way around again.
            Just check your email or our instagram page for the following week’s
            choices.
          </p>
        </div>
        <div class="col-md-4">
          <h3>
            <img src="@/assets/icons/icon-wallet.png" alt="payment" />
            Ordering
          </h3>
          <p>
            Open your weekly email and place your order through the link in the
            menu. Once you receive your payment link, just send your payment and
            your order is complete.
          </p>
        </div>
        <div class="col-md-4">
          <h3>
            <img src="@/assets/icons/icon-house.png" alt="delivery" />
            Delivery
          </h3>
          <p>
            Delivery is free with a minimum order of $60, otherwise it's
            $5/delivery. Dinner arrives at your door each day between 3-6pm.
            Everything is delivered in compostable/recyclable containers so
            please help our planet and dispose of them properly.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>
<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.howWeWork {
  background-color: $teal;
  padding: 1.5rem 0;
  color: white;

  .col-md-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
</style>
