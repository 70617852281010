<template>
  <div class="threeImgGallery">
    <figure class="threeImgGallery__item-1">
      <img
        :src="require('@/assets/images/' + imgOne.url)"
        class="threeImgGallery__img"
        :alt="imgOne.name"
      />
    </figure>
    <figure class="threeImgGallery__item-2">
      <a
        href="https://www.instagram.com/beatsnroots/"
        title="Instagram Beats n Roots"
        class="threeImgGallery-insta"
        v-if="imgTwo.insta"
      >
        <span class="icon icon--black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="20"
            width="20"
          >
            <path
              d="M17,0H7C3.2,0,0,3.2,0,7v10c0,3.9,3.2,7,7,7h10c3.9,0,7-3.1,7-7V7C24,3.2,20.9,0,17,0z M22.6,17.3c0,1.4-0.6,2.7-1.5,3.7
                                s-2.3,1.5-3.7,1.5H6.7C5.3,22.6,4,22,3,21c-1-1-1.6-2.3-1.6-3.7V6.7C1.4,5.3,2,4,3,3c1-1,2.3-1.6,3.7-1.6h10.6C18.7,1.4,20,2,21,3
                                s1.5,2.3,1.5,3.7V17.3z M19.8,5.5c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C19.2,4.2,19.8,4.8,19.8,5.5z M12,6
                                c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S15.3,6,12,6z M12,16.6c-2.6,0-4.6-2.1-4.6-4.6S9.4,7.4,12,7.4s4.6,2.1,4.6,4.6
                                S14.5,16.6,12,16.6z"
            />
          </svg>
        </span>
      </a>
      <img
        :src="require('@/assets/images/' + imgTwo.url)"
        class="threeImgGallery__img"
        :alt="imgTwo.name"
      />
    </figure>
    <figure class="threeImgGallery__item-3">
      <img
        :src="require('@/assets/images/' + imgThree.url)"
        class="threeImgGallery__img"
        :alt="imgThree.name"
      />
    </figure>
  </div>
</template>

<script>
export default {
  name: "ThreeImgGallery",
  props: {
    imgOne: {
      name: String,
      url: String,
    },
    imgTwo: {
      name: String,
      url: String,
      insta: Boolean,
    },
    imgThree: {
      name: String,
      url: String,
    },
  },
  data() {
    return {
      // insta: false,
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vendors/bootstrap/custom";
// Gallery Three Images

.threeImgGallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 0.325rem;
  row-gap: 0.325rem;

  @media screen and (min-width: $screen-lg) {
    grid-template-rows: repeat(2, 14vw);
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__item-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  &__item-2 {
    position: relative;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

    .threeImgGallery-insta {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 1rem;
      background-color: rgba(white, 40%);
      z-index: 1;

      .icon {
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  &__item-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}
</style>
